
.icon {
    margin-top: .8rem;
    margin-right: 1rem;
    width: 3.4rem;
}

p {
    margin: 0;
}

.primaryTitle {
    margin: 3rem 0 1rem;
}

.title-admin {
    font-size: 3.5rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    color: #0054B0;
    margin-top: 1rem;
    /*margin-bottom: 3rem;*/
}

.secondaryTitle p {
    margin: 0 0 0px;
}

.secondaryTitle {
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    color: #0054B0;
    margin-top: 1rem;
}

.secondaryTitle2 p {
    margin: 0 0 0px;
}

.secondaryTitle2 {
    font-size: 2.2rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    /*font-weight: bold;*/
    color: #0054B0;
    /*color: black;*/
    margin-top: 1rem;
}

.secondaryTitle3 p {
    margin: 0 0 0px;
}

.secondaryTitle3 {
    font-size: 2.5rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    /*font-weight: bold;*/
    color: #0054B0;
    margin-top: 1rem;
}

.paperTitle p {
    margin: 0 0 0px;
}

.paperTitle {
    font-size: 1.8rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    /* font-weight: bold; */
    color: #0054B0;
    margin-top: 1rem;
}

.paperAuthor p {
    margin: 0 0 0px;
}

.paperAuthor {
    font-size: 1.6rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    /* font-weight: bold; */
    color: #5b5b5b;
    margin-top: 1rem;
}

.dateText p {
    margin: 0 0 5px;
}

.dateText {
    font-size: 1.8rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    color: black;
    margin-top: 1rem;
}

.secondaryBlack{
    color: black;
}
.noList {
    margin-top: 1rem;
    text-align: justify;
}

.black-text {
    color: #333333 !important;
    font-weight: 500 !important;
}

.title {
    margin-left: -1.8rem !important;
    text-align: left;
    font-size: 3.5rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    color: #202020;
}

.title2 {
    margin-left: -1.8rem !important;
    text-align: left;
    font-size: 3rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    color: #0054B0;
}


.content-text {
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    color: #202020;
}

.demo-text {
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #5b5b5b;
    margin-top: 1rem;
    text-align: justify;
}

.demo-text2 {
    font-size: 1.9rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #5b5b5b;
    margin-top: 1rem;
    text-align: justify;
}

.author-text {
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #5b5b5b;
    /*margin-top: .2rem;*/
    text-align: justify;
}

.author-text2 {
    font-size: 2.3rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #5b5b5b;
    /*margin-top: .2rem;*/
    text-align: justify;
}

.el-rows {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.headset {
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    margin-bottom: .4rem;
    color: #0054B0;

}

.noList {
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    list-style-position: outside;
    color: #0054B0;
}

.noList::marker {
    float: left;
}

.noList > div {
    float: right;
    width: 97%;
}

.retract {
    padding-left: 2.5rem;
}
.retract2 {
    padding-left: 5rem;
    list-style: circle;
}

@media screen and (max-width: 750px) {

    .noList > div {
        width: 95%;
    }
}

.demo-link {
    color: blue;
    border-bottom: blue dotted 1px;
}

.demo-link:hover {
    color: #0054B0;
    border-bottom: #0054B0 dotted 1px;
    text-decoration: none;
}

.text-color {
    color: blue;
}

.textBlue {
    margin-top: 1rem;
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    color: #0054B0;
}

@media screen  and (max-width: 750px) {
    .title {
        margin-left: 1rem !important;
    }

    .icon {
        margin-top: .4rem;
    }


}
